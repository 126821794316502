<script>
import LandingImageComponent from '@rei/landing-image-component';
import { LandingContentBlock } from '@rei/landing-content-block';
import LandingBadge from '@rei/landing-badge';
import palettes from '../../mixins/palettes';

export default {
  name: 'SplitBannerComponentBespoke',
  components: {
    LandingImageComponent,
    LandingContentBlock,
    LandingBadge,
  },
  mixins: [palettes],
  props: {
    media: { type: Object, required: true },
    content: { type: Object, required: true },
    badge: { type: Object, required: false, default: (() => {}) },
    lockupOverlay: { type: Object, required: false, default: (() => {}) },
    caption: { type: String, required: false, default: '' },
  },
};
</script>

<template>
  <div
    class="split-banner-bespoke"
    data-ui="split-banner-bespoke"
    :style="cssSurfacePaletteVars"
  >
    <div class="split-banner-bespoke__wrapper">
      <div class="split-banner-bespoke__media">
        <landing-image-component
          v-bind="media"
          :lazy-load="false"
          class="split-banner-bespoke__image"
          object-fit="cover"
        />
        <landing-badge
          v-if="badge"
          v-bind="badge"
          class="split-banner-bespoke__badge"
        />
        <div class="split-banner-bespoke__lockup-overlay">
          <img
            v-if="lockupOverlay"
            :src="lockupOverlay.src"
            :alt="lockupOverlay.alt"
          >
        </div>
      </div>
      <landing-content-block
        v-bind="content"
        class="split-banner-bespoke__content"
      />
    </div>
    <p
      v-if="caption"
      class="split-banner-bespoke__caption"
    >
      {{ caption }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';
.split-banner-bespoke{

  &__wrapper {
    border-radius: 8px;
    border: 1px solid $cdr-color-border-primary;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: var(--palette-background-color);

    @include cdr-sm-mq-up{
      flex-direction: row;
    }
  }

  &__media {
    position: relative;
    flex-shrink: 0;

    :deep(.landing-image-component) {
      aspect-ratio: 4/3;
      display: block;
      width: 100%;
      height: 100%;
      @include cdr-sm-mq-up{
        aspect-ratio: auto;
      }
    }

    :deep(.landing-image-component__image) {
      width: 100%;
      height: 100%;
    }

    @include cdr-sm-mq-up {
      width: 60%;
      aspect-ratio: unset;
    }

    @include cdr-md-mq-up {
      width: 66.67%;
    }

    @include cdr-lg-mq-up {
      width: 75%;
    }
  }

  &__image {
    @include cdr-sm-mq-up {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__badge{
    position: absolute;
    top: $cdr-space-one-and-a-half-x;
    left: 0;

    @include cdr-sm-mq-up {
      top: $cdr-space-two-x;
    }
  }

  &__lockup-overlay {
    position: absolute;
    display: flex;
    align-items: start;
    justify-content: left;
    left: 16px;
    bottom: 24px;
    height: 64px;
    width: 104px;
    @include cdr-sm-mq-up {
      left: 16px;
      bottom: 32px;
    }
    @include cdr-md-mq-up {
      left: 24px;
      bottom: 32px;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &__content {
    padding: $cdr-space-inset-one-x-stretch;

    @include cdr-sm-mq-up {
      padding: $cdr-space-two-x $cdr-space-one-x;
    }

    @include cdr-md-mq-up {
      padding: $cdr-space-two-x $cdr-space-one-and-a-half-x;
    }
  }

  &__caption {
    @include cdr-text-utility-sans-100;
    margin: $cdr-space-half-x 0 0;
  }
}
</style>
