<script>
import { defineAsyncComponent } from 'vue';
import SimpleTopCategories from '@rei/simple-top-categories';
import DisruptorModal from '@rei/disruptor-modal';
import FilmstripCarousel from '@rei/filmstrip-carousel';
import LandingMiniBanner from '@rei/landing-mini-banner';
import { StoriesComponent, StoryComponent } from '@rei/landing-story';
import { useDynamicContent } from '@rei/cs-preview-fetch';

import HeadingSection from './components/headingSection/headingSection.vue';
import HomepageLead from './components/lead/HomepageLead.vue';
import StoryCardPlacement from '../../common/components/story-card-placement/StoryCardPlacement.vue';
import SecondaryStories from './components/secondary-stories/SecondaryStories.vue';
import InfoCards from '../../common/components/info-cards/InfoCards.vue';
import CouponPlacement from '../../common/components/coupon/CouponPlacement.vue';
import LinkBlockComponent from './components/link-block/LinkBlockComponent.vue';
import UgcPlacementComponent from '../../common/components/ugc-placement-component/UgcPlacementComponent.vue';
import ugcPlacementData from '../../common/mixins/ugcPlacementData';
import formatRRCategories from '../../utils/formatRichRelevance';
import AsyncProductRecommendations from '../../common/async-components/product-recommendations/AsyncProductRecommendations.vue';
import InlineCardsLoader from '../../common/components/loaders/InlineCardsLoader.vue';
import AppDownloadBanner from '../../common/components/app-download/AppDownloadBanner.vue';
import SplitBannerComponentBespoke from '../../common/components/split-banner-component-bespoke/SplitBannerComponentBespoke.vue';

// Mixins and utils
import { mustacheFactory } from '../../utils/formatText';
import formatCurrency from '../../utils/formatMoney';
import getRecs from '../../utils/recommendations';
import logging from '../../common/mixins/logging';
import session from '../../common/mixins/session';
import analytics from '../../common/mixins/analytics';
import webVitals from '../../common/mixins/webVitals';
import { getAndApplyAdobeTargetOffers, getAndApplyEWContent } from '../../utils/getAndApplyAdobeTargetOffers';

import {
  formatImageComponent,
  transformPromotionToContentBlock,
  formatBannerContentStack,
  formatMiniBannerComponent,
  transformLinkCollectionToCategories,
  configDestructuring,
  formatBadgeConfigContentStack,
  transformImageAnchorsToBannerPosition,
  convertAnchorToXY,
  transformPromotionToStory,
  transformPromotionToLandingStories,
  transformContentBlockSize,
  formatAmbientVideo,
} from '../../utils/formatContentStackData';

import {
  formatCategories,
} from '../../utils/formatCmsData';

// LP-4010
import spotlightTestData from './data/spotlightTestData/spotlightTestData.json';

import CompactMemberZone from './components/compact-member-zone/CompactMemberZone.vue';
import CMZData from './components/compact-member-zone/CompactMemberZoneTestData.json';

const topCatRRPlacementType = 'home_page.TopCategories1';

export default {
  name: 'HomePage',
  components: {
    // Lead Zone
    HomepageLead,

    // No Zone
    StoryCardPlacement,
    CouponPlacement,
    AsyncProductRecommendations,
    SecondaryStories,
    StoriesComponent,
    LandingMiniBanner,
    DisruptorModal,
    EmailSignupForm: defineAsyncComponent(() => import('@rei/email-acquisition-modal')),
    LinkBlockComponent,
    SimpleTopCategories,
    InlineCardsLoader,

    // Membership Zone
    SplitBannerComponentBespoke,

    // No Zone
    UgcPlacementComponent,
    FilmstripCarousel,
    StoryComponent,
    InfoCards,
    HeadingSection,
    AppDownloadBanner,
    CompactMemberZone,
  },
  mixins: [
    analytics,
    session,
    ugcPlacementData,
    logging,
    webVitals,
  ],
  props: {
    additionalData: { type: Object, default: () => { } },
    testData: { type: Object, default: () => { } },
    componentModels: { type: Object, default: () => { } },
    adobeTargetOfferEnabled: { type: Boolean, default: false },
    richRelevanceEnabled: { type: Boolean, default: true },
  },
  setup(props) {
    const getPreviewUrl = () => `/home/rs/live-preview?entryId=${props.componentModels?.uid}`;
    const { content: reactiveComponentModels } = useDynamicContent(props, getPreviewUrl);
    if (reactiveComponentModels.value?.componentModels) {
      reactiveComponentModels.value = reactiveComponentModels.value.componentModels;
      // LP-4010 Add spotlight test data
      reactiveComponentModels.value.spotLightTest = {};
    }
    // Add memberZone to content
    reactiveComponentModels.value.memberZone = {};
    return {
      reactiveComponentModels,
    };
  },
  data() {
    return {
      recsPlacements: {
        ...this.richRelevanceEnabled && { status: 'LOADING' },
        config: [
          { placementType: 'home_page.core_ol_rec_1' },
          { placementType: 'home_page.core_ol_rec_2' },
          { placementType: topCatRRPlacementType }],
      },
      adobeTargetOffer: {
        ...this.adobeTargetOfferEnabled && { status: 'LOADING' },
      },
      isMounted: false,
      leadInfo: {
        status: 'READY',
        data: this.reactiveComponentModels?.lead,
        className: '',
      },
      // LP-3470
      isMemberZoneTestActive: false,
      isMemberZoneTestControl: false,
      isMemberZoneTestA: false,
      isMemberZoneTestB: false,
      // LP-4010
      spotLightTestBannerData: spotlightTestData,
      isSpotLightTestActive: false,
      isSpotLightTestControl: false,
      isSpotLightTestA: false,
      isSpotLightTestB: false,
      spotLightImpressionSent: false,
      spotLightImpressionB: false,
    };
  },
  computed: {
    /*
    * Rendering map for zones.
    * Zones are counted in a separate rendering map so they don't get counted in the analytics map.
    */
    zoneRenderingMap() {
      return {
        'membership-zone': !!this.membershipZone,
      };
    },
    /*
    * Rendering map for components.
    * Components must be listed here in order of appearance as their analytics value
    * is calculated based on their position in this list.
    */
    renderingMap() {
      return {
        lead: this.lead,
        'lead-cards': this.hasLeadCards,
        'recommendations-1-recently-viewed': !!(this.recsPlacements.ready && !!this.recsPlacements.data?.[0] && this.isRecentlyViewed),
        'new-member-zone': this.isMemberZoneTestActive && this.isMemberZoneTestB,
        'old-member-zone': this.isMemberZoneTestActive && this.isMemberZoneTestA,
        'membership-placement': !!this.membershipPlacement,
        'why-join-rei': !!this.whyJoinRei,
        clearance: !!this.clearance,
        coupon: !!this.couponPlacement,
        'curated-shopping-zone-a': !!this.curatedShoppingZones[0],
        'top-categories': !!this.topCategories,
        'curated-shopping-zone-b': !!this.curatedShoppingZones[1],
        'ways-to-save': !!this.waysToSave,
        'membership-stories': !!this.membershipStories,
        'secondary-stories': !!this.secondaryStories,
        'membership-zone-split-banner': !!this.membershipZoneSplitBanner,
        outlet: !!this.outletModel,
        'ugc-gallery': !!this.ugcPlacementModule && !!this.ugcPlacementContent && !!this.ugcModuleEnabled,
        'recommendations-1': !!(this.recsPlacements.ready && !!this.recsPlacements.data?.[0] && !this.isRecentlyViewed),
        'recommendations-2': !!(this.recsPlacements.ready && !!this.recsPlacements.data?.[1]),
        'why-shop-rei': !!this.whyShopRei,
        'link-block': !!this.computedLinkBlock,
        'acquisition-modal': !!this.emailModalEnabled,
        'spot-light-test': !!this.isSpotLightTestActive,
      };
    },
    uid() {
      const { uid } = this.reactiveComponentModels || {};
      return uid;
    },
    // Keeping this for experiments that require a change
    // for the lead CSS
    computedLeadClassName() {
      const { className } = this.leadInfo; // ssred classname
      return className;
    },
    lead() {
      const {
        lead,
        associatedPromotions,
      } = this.reactiveComponentModels || {};

      const {
        config,
      } = lead || {};
      const secondaryButtonsOnly = configDestructuring(lead, 'secondaryButtonsOnly');
      const actionsOpt = {
        allSecondaryButtons: secondaryButtonsOnly,
      };

      const { promotions = [] } = associatedPromotions || {};

      const contentAnchor = configDestructuring(lead, 'anchor');
      const contentAnchorMobile = configDestructuring(lead, 'anchorMobile');
      const textAlignment = configDestructuring(lead, 'copyAlignment');
      const textAlignmentDesktop = configDestructuring(lead, 'copyAlignmentDesktop');
      const contentPosition = {
        xs: contentAnchorMobile,
        sm: contentAnchor,
      };
      const contentAlign = {
        xs: textAlignment,
        sm: textAlignmentDesktop,
      };

      const bannerDefaults = {
        bannerPosition: transformImageAnchorsToBannerPosition(lead?.imageAnchors),
        contentPosition,
      };
      const bannerComponentProps = (
        formatBannerContentStack({
          ...config,
        }, bannerDefaults)
      );
      const palette = configDestructuring(lead, 'palette');
      const cardPalette = (palette?.content === 'sale' || palette?.content === 'sale-inverse')
        ? 'sale'
        : 'primary';

      // LP-3356
      const contentBlock = transformPromotionToContentBlock(
        lead,
        {
          ...config,
          palette,
          contentAlign,
        },
        {
          level: 'h1',
          size: transformContentBlockSize(lead?.config?.contentSize),
        },
        actionsOpt
      );

      const video = {
        src: lead?.video?.ambientVideo && formatAmbientVideo(lead.video.ambientVideo),
        poster: lead?.image?.xs,
        ratio: '56-73',
        cover: true,
      };

      return {
        status: 'READY',
        className: this.computedLeadClassName,
        modelName: null,
        data: {
          media: formatImageComponent(lead?.image),
          palette,
          content: contentBlock,
          bannerComponentProps,
          video,
          cards: promotions.reduce((acc, promotion, index) => {
            const {
              copy,
              image,
              links,
            } = promotion || {};
            const { description, subheading } = copy || {};
            const [{
              href,
            } = {}] = links || [];

            acc[`card-${index}`] = {
              media: formatImageComponent(image, {
                renderedSizes: {
                  mobile: '100vw',
                  desktop: '550px',
                },
              }),
              heading: subheading,
              body: description,
              target: href,
              badge: formatBadgeConfigContentStack(promotion, palette.content),
              palette: { content: cardPalette },
            };

            return acc;
          }, {}),
          hasCards: this.hasLeadCards,
          videoFocalPoint: null,
        },
      };
    },
    hasLeadCards() {
      const { associatedPromotions = {} } = this.reactiveComponentModels || {};
      const { promotions = [] } = associatedPromotions || {};

      return promotions && !!promotions.length;
    },
    memberData() {
      const { firstName, dividendBalance } = this.user || {};

      return {
        name: `<b class="accent-text">${firstName || 'REI Member'}</b>`,
        balance: `<b class="accent-text">${formatCurrency(dividendBalance)}</b>`,
      };
    },
    membershipPlacement() {
      const {
        membership,
      } = this.reactiveComponentModels || {};
      const mediaOpt = { radius: 'rounded', renderedSizes: { mobile: '750px', desktop: '600px' } };
      const headingOpts = {
        level: 'h2',
        size: 'md-light',
      };

      const memberTemplate = mustacheFactory(this.memberData);
      const isMember = this.user?.isMember;
      const { member = {}, noReward = {}, nonMember } = membership || {};
      const memberPromotion = this.user?.dividendBalance > 1 ? member : noReward;
      const memberExperience = {
        ...memberPromotion,
        copy: {
          ...memberPromotion?.copy,
          heading: memberTemplate(memberPromotion?.copy?.heading),
        },
      };

      const nonMemberExperience = nonMember;
      const promotion = isMember ? memberExperience : nonMemberExperience;
      const palette = configDestructuring(membership, 'palette');
      const actionsOpts = { allSecondaryButtons: configDestructuring(membership, 'secondaryButtonsOnly') };

      const story = [
        transformPromotionToStory(
          promotion,
          { palette },
          headingOpts,
          actionsOpts,
          promotion?.image,
          mediaOpt
        ),
      ];

      return (membership && ({
        id: isMember ? 'membership-placement' : 'membership-nonmember-placement',
        props: {
          stories: story,
          grid: 'single',
          aspectRatio: '4/3',
          storyType: 'single',
          palette: configDestructuring(membership, 'palette'),
        },
      })) || null;
    },
    whyJoinRei() {
      const isNonMember = !this.loading && (this.user && !this.user.isMember);

      const {
        whyJoinRei,
      } = this.reactiveComponentModels || {};

      const renderComponent = !!whyJoinRei && isNonMember;

      return (renderComponent && ({
        heading: {
          heading: {
            text: whyJoinRei.heading,
            size: 'sm-standard',
          },
        },
        cards: whyJoinRei.infoCards.map((card) => ({
          lockup: formatImageComponent(card.image),
          heading: card.heading,
          body: card.description,
          link: {
            text: card.link?.title,
            href: card.link?.href,
          },
        })),
      })) || null;
    },
    membershipZoneData() {
      const renderComponent = this.isMemberZoneTestActive;
      const data = CMZData;
      const infoCards = data.controlData.infoCards
        .map((e) => {
          e.lockup = formatImageComponent(e.image);
          return e;
        });
      const controlXSImages = data.controlData.storiesComponent.stories[0].media.images.xs;
      const allControlImages = {
        xs: controlXSImages,
        sm: controlXSImages,
        md: controlXSImages,
        lg: controlXSImages,
      };
      data.controlData.storiesComponent.stories[0].media.images = allControlImages;
      // adds required prop storyType
      data.controlData.storiesComponent.stories.map((story) => ({ ...story, storyType: 'double' }));
      data.controlData.cards = infoCards;
      return (renderComponent && (data));
    },
    whyShopRei() {
      const {
        whyShopRei,
      } = this.reactiveComponentModels || {};

      return (whyShopRei && ({
        heading: {
          heading: {
            text: whyShopRei.heading,
            size: 'sm-standard',
          },
        },
        cards: whyShopRei.infoCards.map((card) => ({
          lockup: formatImageComponent(card.image),
          heading: card.heading,
          body: card.description,
          link: {
            text: card.link?.title,
            href: card.link?.href,
          },
        })),
      })) || null;
    },
    clearance() {
      const { clearancePlacement } = this.reactiveComponentModels || {};
      const config = {
        contentAlign: {
          xs: 'center',
          sm: 'left',
        },
      };
      const headingOpts = {
        level: 'h2',
        size: 'sm-standard',
      };

      const {
        promotion,
        promotion: {
          image,
        } = {},
      } = clearancePlacement || {};

      const palette = configDestructuring(clearancePlacement, 'palette');
      const secondaryButtonsOnly = configDestructuring(clearancePlacement, 'secondaryButtonsOnly');
      const actionsOpt = {
        allSecondaryButtons: secondaryButtonsOnly,
      };
      const media = formatImageComponent(image);
      const renderComponent = !!clearancePlacement;

      return (renderComponent && {
        palette,
        media,
        content: transformPromotionToContentBlock(
          promotion,
          { ...config, palette },
          headingOpts,
          actionsOpt
        ),
      }) || null;
    },
    membershipStories() {
      const { membershipStories } = this.reactiveComponentModels || {};
      const palette = configDestructuring(membershipStories?.promotionSections?.[0], 'palette');
      const contentBlockOpts = {
        palette,
      };
      const contentBlockHeadingOpts = {
        size: 'xs',
        level: 'h3',
        alignText: 'left',
      };
      const headingOpts = {
        size: 'sm-standard',
        level: 'h2',
        alignText: 'left',
      };
      const heading = membershipStories?.heading;
      const promotions = membershipStories?.promotionSections?.map((promotionSection) => ({
        ...promotionSection.promotion,
        actionsOptions: {
          allSecondaryButtons: configDestructuring(promotionSection, 'secondaryButtonsOnly'),
        },
      }));
      const mediaOptions = {
        renderedSizes: {
          mobile: '100vw',
          desktop: '400px',
        },
      };
      const landingStoryConfig = {
        grid: (promotions?.length % 2 === 0) ? 'double' : 'triple',
        aspectRatio: '5/4',
        palette,
      };
      const stories = transformPromotionToLandingStories(
        promotions,
        landingStoryConfig,
        heading,
        headingOpts,
        contentBlockOpts,
        contentBlockHeadingOpts,
        mediaOptions,
      );
      const renderComponent = !!membershipStories;

      return (renderComponent && stories) || null;
    },
    couponPlacement() {
      const {
        couponPlacement,
      } = this.reactiveComponentModels || {};
      const palette = configDestructuring(couponPlacement, 'palette');

      const {
        couponInformation: {
          startDate,
          endDate,
          couponCode,
          image,
        } = {},
        categories = {},
      } = couponPlacement || {};

      return couponPlacement && {
        palette,
        coupon: {
          content: transformPromotionToContentBlock({
            ...couponPlacement,
            ...couponPlacement.content,
            copy: {
              ...couponPlacement.content,
            },
          }, {
            palette,
          }),
          media: formatImageComponent(image),
          mergedMediaAndActions: {
            text: {
              heading: {
                text: categories.heading,
              },
            },
            mediaAndActions: {
              categories: categories.categories.reduce((acc, item, index) => {
                acc[`category-${index + 1}`] = {
                  text: item?.link?.title,
                  target: item?.link?.href,
                  media: {
                    alt: item?.image?.altText,
                    src: item?.image?.src,
                  },
                };

                return acc;
              }, {}),
            },
          },
          children: {
            'resource-1': {
              config: {
                'start-date': startDate,
                'end-date': endDate,
                'coupon-code': couponCode,
              },
            },
          },
        },
      };
    },
    couponVerticalMargin() {
      const { coupon } = this.reactiveComponentModels || {};
      return coupon?.config?.['has-vertical-margin'] ? '' : 'stack-exception';
    },
    curatedShoppingZones() {
      const { curatedShoppingZones } = this.reactiveComponentModels || {};
      return curatedShoppingZones?.reduce((acc, filmStrip) => {
        acc.push({
          ...filmStrip,
          content: {
            ...filmStrip.content,
            heading: {
              ...filmStrip.content.heading,
              size: 'sm-standard',
            },
          },
          slides: filmStrip?.slides?.map((slide) => ({
            cta: {
              ...slide?.cta,
              target: slide?.cta?.href,
            },
            media: formatImageComponent(slide.media, {
              renderedSizes: {
                mobile: '33vw',
                desktop: '25vw',
              },
            }),
          })),
        });

        return acc;
      }, []) || [];
    },
    topCategories() {
      // If recs are loading, delay till they're done
      if (this.recsPlacements.status === 'LOADING') {
        return {
          status: 'LOADING',
        };
      }
      // Load up those recs
      const recsPlacement = this.recsPlacements?.data?.find((rec) => (
        rec.recsName === topCatRRPlacementType)) || {};
      const { topCategories } = this.reactiveComponentModels || {};
      const {
        categories: csCategories, heading: csHeading, cta, subheading,
      } = topCategories || {};

      const mappedCategories = transformLinkCollectionToCategories({ links: csCategories }, 'categories');
      const personalizedCategories = formatCategories(formatRRCategories(recsPlacement));
      const categories = [].concat(personalizedCategories, mappedCategories).slice(0, 10);

      const { title: text, href: target } = cta || {};

      const heading = personalizedCategories.length ? 'Top categories for you' : csHeading;

      const renderComponent = !!mappedCategories.length;

      return (
        (renderComponent && {
          ready: true,
          heading: heading || 'Top categories',
          simpleTopProps: {
            grid: '5',
            cta: {
              text,
              target,
              theme: 'sale',
            },
            categories,
            hasBackground: true,
            footerAlignment: 'right',
            body: subheading,
          },
        })
        || null
      );
    },
    waysToSave() {
      const { waysToSave } = this.reactiveComponentModels || {};
      const { promotionSections, heading } = waysToSave || {};

      const landingStoryConfig = {
        grid: configDestructuring(waysToSave, 'storyRowCount'),
        storyStackPosition: configDestructuring(waysToSave, 'storyStackPosition'),
        aspectRatio: '5/4',
      };

      const contentBlockHeadingOpts = {
        size: (heading || promotionSections?.length > 1) ? 'xs' : 'sm-standard',
        level: heading ? 'h3' : 'h2',
        alignText: 'left',
      };

      const mediaOptions = {
        ratio: '5/4',
        renderedSizes: {
          mobile: '100vw',
          desktop: '400px',
        },
      };

      const stories = promotionSections?.map((promotionSection, index) => {
        const { promotion } = promotionSection || {};
        const storyPalette = configDestructuring(promotionSection, 'palette');
        return transformPromotionToStory(
          promotion,
          {
            palette: storyPalette,
          },
          contentBlockHeadingOpts,
          {
            ...promotion.actionsOptions,
            horizontalSection: index + 1,
          },
          promotion.image,
          mediaOptions,
          storyPalette,
        );
      }) || null;

      return (!!waysToSave && {
        heading,
        stories,
        ...landingStoryConfig,
        palette: stories[0]?.palette,
      }) || null;
    },
    secondaryStories() {
      const { secondaryStories } = this.reactiveComponentModels || {};

      const {
        heading,
        promotionSections = [{}],
      } = secondaryStories || {};

      return secondaryStories && {
        text: { heading: { text: heading } },
        children: promotionSections.reduce((acc, promotionSection, index) => {
          const { promotion, config } = promotionSection || {};

          const {
            copy,
            image,
            links,
            title,
            imageAnchor,
          } = promotion || {};
          const { body, heading: storyHeading } = copy || {};
          const [{
            title: primaryLinkTitle,
            href: primaryLinkHref,
          } = {},
          {
            title: standaloneLinkTitle,
            href: standaloneLinkHref,
          } = {}] = links || [];

          acc[`story-${index}`] = {
            media: formatImageComponent(image, {
              renderedSizes: {
                mobile: '(min-width: 768px) 50vw, 100vw',
                desktop: '568px',
              },
            }),
            text: { heading: { text: storyHeading }, body: { text: body } },
            actions: {
              cta: {
                text: primaryLinkTitle,
                target: primaryLinkHref,
              },
              'standalone-link': {
                text: standaloneLinkTitle,
                target: standaloneLinkHref,
              },
            },
            config: {
              theme: !config?.effect ? 'dark' : undefined,
              imageAnchor: imageAnchor ? convertAnchorToXY(imageAnchor.toLowerCase()) : 'center center',
            },
            modelName: title,
          };

          return acc;
        }, {}),
      };
    },
    outletModel() {
      const {
        outlet,
      } = this.reactiveComponentModels || {};

      const { promotion, config } = outlet || {};
      const actionsOpt = {
        allSecondaryButtons: config?.secondaryButtonsOnly,
      };
      const palette = {
        content: 'primary',
        surface: 'secondary',
      };

      return promotion
        && formatMiniBannerComponent(promotion, { palette }, {}, actionsOpt);
    },
    emailModalEnabled() {
      return this.additionalData?.['email-modal-enabled'] && this.isMounted;
    },
    computedLinkBlock() {
      const { linkBlock } = this.reactiveComponentModels || {};
      const {
        image,
        linkGroups,
      } = linkBlock || {};

      return linkBlock && {
        blocks: linkGroups.map(({ heading, links }) => ({
          heading,
          links: links.map(({ href, title }, index) => ({
            key: index,
            href,
            anchor: title,
          })),
        })),
        media: formatImageComponent(image, {
          renderedSizes: {
            mobile: '100vw',
            desktop: '40vw',
          },
        }),
      };
    },
    membershipZoneSplitBanner() {
      const { split_banner: splitBanner } = this.reactiveComponentModels
        ?.membershipZone
        ?.componentModels || {};
      const { content, visualConfiguration } = splitBanner || {};
      const renderComponent = !!splitBanner;
      return (renderComponent && {
        content: {
          ...content,
          lockup: formatImageComponent(content.lockup),
          heading: {
            ...content?.heading,
            size: visualConfiguration?.contentSize ?? 'sm-standard',
          },
          actions: {
            ...content?.actions,
            allSecondaryButtons: visualConfiguration?.secondaryButtonsOnly,

          },
          config: {
            palette: visualConfiguration?.palette,
          },
        },
        badge: (content?.badge && {
          text: content?.badge,
          theme: 'sale-dark',
        }) || null,
        media: formatImageComponent(content?.media),
        palette: visualConfiguration?.palette,
      }) || null;
    },
    ugcPlacementContent() {
      const config = { contentAlign: { xs: 'center' } };
      const headingOpts = { level: 'h2', size: 'md-standard' };
      const { ugcGallery } = this.reactiveComponentModels || {};
      const {
        heading,
        description,
      } = ugcGallery || {};

      return (ugcGallery
        && transformPromotionToContentBlock({
          copy: {
            heading,
            description,
          },
        }, config, headingOpts)
      ) || null;
    },
    membershipZone() {
      const { membershipZone } = this.reactiveComponentModels || {};
      const { heading } = membershipZone || {};
      const renderZone = !!membershipZone;
      return (renderZone && {
        heading,
      }) || null;
    },
  },
  async mounted() {
    const promises = [];
    if (this.recsPlacements.config && this.richRelevanceEnabled) {
      promises.push(this.initRecs());
    }

    // Check if in live preview mode
    const isLivePreview = typeof window !== 'undefined' && window.document.getElementById('cs-live-preview');
    // Handle Adobe setups
    if (this.adobeTargetOfferEnabled && !isLivePreview) {
      promises.push(this.initAdobe());
      // LP-4010
      promises.push(this.initAdobe({ mbox: 'home-page-components-2' })
        .then(this.handleSpotLightTest));
    }

    if (promises.length) {
      await Promise.all(promises)
      /* eslint-disable no-console */
        .catch(() => console.error);
    }

    this.isMounted = true;
  },
  methods: {
    async initAdobe(options) {
      return new Promise((resolve, reject) => {
        const onSuccess = (data) => {
          if (data) {
            this.reactiveComponentModels = data;
          }

          this.adobeTargetOffer.status = 'SUCCESS';
          resolve(data);
        };

        const onError = () => {
          this.adobeTargetOffer.status = 'ERROR';
          reject();
        };

        /**
         * Checks for EdgeWorker Content and applies it on the first `mounted()`.
         * Skip EdgeWorker content check on subsequent calls if application has been attempted.
         * There is no need to pass `options` as those are provided server-side
         */
        if (!this.ewContentApplyAttempted) {
          this.ewContentApplyAttempted = true;
          getAndApplyEWContent(
            this.reactiveComponentModels,
            onSuccess,
            onError,
          );
        }

        // Option to retrieve data client-side for unhandled EdgeWorker conditional logic.
        if (this.adobeTargetOffer.status === 'ERROR') {
          getAndApplyAdobeTargetOffers(this.reactiveComponentModels, onSuccess, onError, options);
        }
      })
        // eslint-disable-next-line
        .catch((e) => console.log('adobe init failed', e));
    },
    async initRecs() {
      return getRecs(this.recsPlacements.config, 'home', null, 'home-page').then((data) => {
        this.recsPlacements.data = data;

        // moves recently viewed recs below lead
        this.isRecentlyViewed = data?.[0]?.recsStrategy === 'RecentHistoricalItems';

        this.recsPlacements.ready = true;
        this.recsPlacements.status = 'SUCCESS';

        return Promise.resolve(data);
      }).catch(() => {
        this.recsPlacements.ready = true;
        this.recsPlacements.status = 'ERROR';

        return Promise.reject();
      });
    },
    // LP-3470
    handleMembershipZoneTest() {
      const { memberZone } = this.reactiveComponentModels;
      const { variant } = memberZone || {};
      const hasVariant = !!variant;
      const query = typeof window !== 'undefined' ? window?.location.search : '';
      const params = new URLSearchParams(query);
      const queryVar = params.get('variant');
      this.isMemberZoneTestActive = params.get('testName') === 'MemberZoneTest' || hasVariant;
      this.isMemberZoneTestControl = (queryVar || variant) === 'Control';
      this.isMemberZoneTestA = (queryVar || variant) === 'Var1';
      this.isMemberZoneTestB = (queryVar || variant) === 'Var2';
    },
    handleSpotLightTest(data) {
      const { spotLightTest } = this.reactiveComponentModels;
      const { variant } = spotLightTest || {};
      this.isSpotLightTestActive = !!variant;
      this.isSpotLightTestControl = variant === 'control';
      this.isSpotLightTestA = variant === 'challenger-a';
      this.isSpotLightTestB = variant === 'challenger-b';
      // Section A observer
      const sectionAObserver = new IntersectionObserver((entries) => {
        if (entries[0].intersectionRatio <= 0) return;
        if (!this.spotLightImpressionSent) {
          // eslint-disable-next-line no-unused-expressions
          window && window.metrics && window.metrics.link({ linkName: 'rei:brand placement impression_Top Categories' });
          this.spotLightImpressionSent = true;
        }
      });
      // Section B observer
      const sectionBObserver = new IntersectionObserver((entries) => {
        if (entries[0].intersectionRatio <= 0) return;
        if (!this.spotLightImpressionB) {
          // eslint-disable-next-line no-unused-expressions
          window && window.metrics && window.metrics.link({ linkName: 'rei:brand placement impression_WaystoSave' });
          this.spotLightImpressionB = true;
        }
      });

      if (this.isSpotLightTestActive) {
        if (this.isSpotLightTestA) {
          sectionAObserver.observe(document?.getElementById('spotlightTestTracker-a'));
        } if (this.isSpotLightTestB) {
          sectionBObserver.observe(document?.getElementById('spotlightTestTracker-b'));
        } if (this.isSpotLightTestControl) {
          sectionAObserver.observe(document?.getElementById('spotlightTestTracker-a'));
          sectionBObserver.observe(document?.getElementById('spotlightTestTracker-b'));
        }
      }
      return Promise.resolve(data);
    },
  },
};
</script>
<template>
  <div>
    <div
      id="homepage"
      class="stack homepage"
      data-ui="home-page"
    >
      <section
        v-if="renderingMap['lead']"
        data-ui="lead"
        class="homepage__lead"
        :class="lead.className"
      >
        <homepage-lead
          :lead-analytics="`${analyticsMap['lead']}_${lead.modelName}`"
          :lead-card-analytics="`${analyticsMap['lead-cards']}_${lead.modelName}`"
          v-bind="lead.data"
        />
      </section>

      <!-- Recommendations 1 (Recently Viewed) -->
      <section
        v-if="renderingMap['recommendations-1-recently-viewed']"
        :data-location-name="analyticsMap['recommendations-1-recently-viewed']"
        data-ui="recommendations-1-recently-viewed"
        class="homepage__container homepage__recommendations"
      >
        <async-product-recommendations
          :placement="recsPlacements.data[0]"
          :metrics="metrics"
          use-custom-heading
        >
          <template #customHeading>
            <heading-section
              :heading="recsPlacements.data[0].recsMessage"
            />
          </template>
        </async-product-recommendations>
      </section>

      <div
        v-if="renderingMap['membership-placement']
          || renderingMap['why-join-rei']
          || !!membershipZoneData"
        id="membership-zone"
        class="membership__zone"
      >
        <template v-if="membershipZoneData && !isMemberZoneTestControl">
          <!-- LP-3458-->
          <section
            v-if="isMemberZoneTestB"
            class="homepage__container"
            data-ui="membership"
            :data-location-name="analyticsMap['new-member-zone']"
          >
            <compact-member-zone :test-data="membershipZoneData" />
          </section>
          <template v-else-if="isMemberZoneTestA">
            <!-- LP-3470-->
            <section
              class="homepage__container membership__placement"
              data-ui="membership"
              :data-location-name="analyticsMap['old-member-zone']"
            >
              <stories-component
                v-bind="membershipZoneData.controlData.storiesComponent"
              />
              <img
                class="membership__placement-handwriting"
                src="https://www.rei.com/dam/18228713_mm-handwritten-hike-sagebrush.svg"
                alt="Take a Hike!"
              >
            </section>
            <section
              class="homepage__container membership__why-join"
              data-ui="why-join-rei"
            >
              <infoCards v-bind="membershipZoneData.controlData" />
            </section>
          </template>
        </template>
        <template v-else>
          <!-- Membership placement -->
          <section
            v-if="renderingMap['membership-placement']"
            data-ui="membership"
            :data-location-name="analyticsMap['membership-placement']"
            class="membership__placement"
          >
            <stories-component
              :data-ui="membershipPlacement.id"
              v-bind="membershipPlacement.props"
            />
            <img
              class="membership__placement-handwriting"
              src="https://www.rei.com/dam/18228713_mm-handwritten-hike-sagebrush.svg"
              alt="Take a Hike!"
            >
          </section>

          <!-- Why Join REI -->
          <section
            v-if="renderingMap['why-join-rei']"
            :data-location-name="analyticsMap['why-join-rei']"
            data-ui="why-join-rei"
            class="homepage__container membership__why-join"
          >
            <info-cards v-bind="whyJoinRei" />
          </section>
        </template>
      </div>

      <!-- Clearance -->
      <section
        v-if="renderingMap['clearance']"
        :data-location-name="analyticsMap['clearance']"
        data-ui="clearance"
        class="homepage__container homepage__clearance"
      >
        <story-card-placement v-bind="clearance" />
      </section>

      <!-- Coupon -->
      <coupon-placement
        v-if="renderingMap.coupon"
        v-bind="couponPlacement"
        :data-location-name="analyticsMap.coupon"
        :class="[couponVerticalMargin]"
        data-ui="coupon"
      />

      <!-- Curated Shopping Zone A -->
      <section
        v-if="renderingMap['curated-shopping-zone-a']"
        id="curated-shopping-zone-a"
        data-ui="curated-shopping-zone-a"
        :data-location-name="analyticsMap['curated-shopping-zone-a']"
        class="homepage__container"
      >
        <filmstrip-carousel v-bind="curatedShoppingZones[0]" />
      </section>

      <!-- Top Categories -->
      <section
        v-if="renderingMap['top-categories']"
        id="top-categories"
        data-ui="top-categories"
        :data-location-name="analyticsMap['top-categories']"
        class="homepage__container"
      >
        <template v-if="topCategories.status === 'LOADING'">
          <inline-cards-loader
            include-heading
            :count="10"
            :columns="{ xs: 3, sm: 5 }"
          />
        </template>
        <template v-else>
          <heading-section :heading="topCategories.heading" />
          <simple-top-categories v-bind="topCategories.simpleTopProps" />
        </template>
      </section>

      <!-- LP-4010 Spotlight test below top categories-->
      <section
        v-if="isSpotLightTestActive && isSpotLightTestA"
        id="spot-light-zone-a"
        data-ui="spot-light-zone-a"
        :data-location-name="analyticsMap['spot-light-zone']"
        class="homepage__container"
      >
        <split-banner-component-bespoke
          :media="spotLightTestBannerData.media"
          :content="spotLightTestBannerData.content"
          :badge="spotLightTestBannerData.badge"
          :lockup-overlay="spotLightTestBannerData.lockupOverlay"
          :caption="spotLightTestBannerData.caption"
        />
      </section>
      <!-- impression trackers below top categories -->
      <div class="impression-tracker">
        <div
          id="spotlightTestTracker-a"
          class="homepage__impression--track"
        />
      </div>

      <!-- Curated Shopping Zone B -->
      <section
        v-if="renderingMap['curated-shopping-zone-b']"
        id="curated-shopping-zone-b"
        data-ui="curated-shopping-zone-b"
        :data-location-name="analyticsMap['curated-shopping-zone-b']"
        class="homepage__container"
      >
        <filmstrip-carousel v-bind="curatedShoppingZones[1]" />
      </section>

      <!-- Ways to save -->
      <section
        v-if="renderingMap['ways-to-save']"
        class="homepage__container"
        :data-location-name="analyticsMap['ways-to-save']"
        data-ui="ways-to-save"
      >
        <heading-section
          v-if="waysToSave.heading"
          :heading="waysToSave.heading"
        />
        <!--Stories-->
        <stories-component
          :grid="waysToSave.grid"
          :story-stack-position="waysToSave.storyStackPosition"
          :palette="waysToSave.palette"
        >
          <template #story>
            <story-component
              v-for="(story, index) in waysToSave.stories"
              :key="`ways-to-save-story-${index}`"
              v-bind="story"
              :story-type="waysToSave.grid"
              :story-stack-position="waysToSave.storyStackPosition"
              :palette="story?.palette"
            />
          </template>
        </stories-component>
      </section>

      <!-- Membership Stories -->
      <section
        v-if="renderingMap['membership-stories']"
        class="homepage__container"
        :data-location-name="analyticsMap['membership-stories']"
        data-ui="home-membership-stories"
      >
        <heading-section :heading="membershipStories.headingSection.text" />
        <stories-component
          :grid="membershipStories.grid"
          :story-stack-position="membershipStories.storyStackPosition"
        >
          <template #story>
            <story-component
              v-for="(story, index) in membershipStories.stories"
              :key="`story-${index}`"
              v-bind="story"
            />
          </template>
        </stories-component>
      </section>

      <!-- Secondary Stories -->
      <secondary-stories
        v-if="renderingMap['secondary-stories']"
        :secondary-stories="secondaryStories"
        :data-location-name="analyticsMap['secondary-stories']"
        data-ui="secondary-stories"
      />

      <!-- Membership zone -->
      <section
        v-if="zoneRenderingMap['membership-zone']"
        data-ui="membership-zone"
        class="homepage__container"
      >
        <heading-section
          v-if="membershipZone.heading"
          :heading="membershipZone.heading.text" />

        <split-banner-component-bespoke
          v-if="renderingMap['membership-zone-split-banner']"
          :data-location-name="analyticsMap['membership-zone-split-banner']"
          v-bind="membershipZoneSplitBanner"
        />
      </section>

      <!-- Outlet promo -->
      <section
        v-if="renderingMap.outlet"
        :data-location-name="analyticsMap.outlet"
        data-ui="outlet"
        class="homepage__container homepage__outlet"
      >
        <landing-mini-banner v-bind="outletModel" />
      </section>

      <!-- LP-4010 Spotlight test above UGC -->
      <section
        v-if="isSpotLightTestActive && isSpotLightTestB"
        id="spot-light-zone-b"
        data-ui="spot-light-zone-b"
        :data-location-name="analyticsMap['spot-light-zone']"
        class="homepage__container"
      >
        <split-banner-component-bespoke
          :media="spotLightTestBannerData.media"
          :content="spotLightTestBannerData.content"
          :badge="spotLightTestBannerData.badge"
          :lockup-overlay="spotLightTestBannerData.lockupOverlay"
          :caption="spotLightTestBannerData.caption"
        />
      </section>
      <!-- impression trackers above UGC -->
      <div class="impression-tracker">
        <div
          id="spotlightTestTracker-b"
          class="homepage__impression--track"
        />
      </div>

      <!-- Ugc placement -->
      <section
        v-if="renderingMap['ugc-gallery']"
        class="homepage__container"
        :data-location-name="analyticsMap['ugc-gallery']"
        data-ui="home-ugc"
      >
        <ugc-placement-component
          v-bind="ugcPlacementModule"
          :content="ugcPlacementContent"
          :has-more-items="hasMoreItems.hasMore"
          @onLoadMore="loadMoreUgcData"
        />
      </section>

      <!-- Recommendations 1 -->
      <section
        v-if="renderingMap['recommendations-1']"
        :data-location-name="analyticsMap['recommendations-1']"
        data-ui="recommendations-1"
        class="homepage__container homepage__recommendations"
      >
        <async-product-recommendations
          :placement="recsPlacements.data[0]"
          :metrics="metrics"
          use-custom-heading
        >
          <template #customHeading>
            <heading-section
              :heading="recsPlacements.data[0].recsMessage"
            />
          </template>
        </async-product-recommendations>
      </section>

      <!-- Recommendations 2 -->
      <section
        v-if="renderingMap['recommendations-2']"
        :data-location-name="analyticsMap['recommendations-2']"
        data-ui="recommendations-2"
        class="homepage__container homepage__recommendations"
      >
        <async-product-recommendations
          :placement="recsPlacements.data[1]"
          :metrics="metrics"
          use-custom-heading
        >
          <template #customHeading>
            <heading-section
              :heading="recsPlacements.data[1].recsMessage"
            />
          </template>
        </async-product-recommendations>
      </section>

      <!-- Why Shop REI -->
      <section
        v-if="renderingMap['why-shop-rei']"
        :data-location-name="analyticsMap['why-shop-rei']"
        data-ui="why-shop-rei"
        class="homepage__container"
      >
        <info-cards v-bind="whyShopRei" />
      </section>

      <!-- Link block -->
      <link-block-component
        v-if="renderingMap['link-block']"
        v-bind="computedLinkBlock"
        data-ui="link-block"
        :data-location-name="analyticsMap['link-block']"
      />

      <!-- Disruptor modal -->
      <div v-if="renderingMap['acquisition-modal'] && isMounted">
        <disruptor-modal
          data-analytics-config="exclude-all"
          label=""
        >
          <template #default="{ closeFromContent }">
            <email-signup-form
              @close="closeFromContent"
            />
          </template>
        </disruptor-modal>
      </div>

      <!-- App download banner -->
      <AppDownloadBanner />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';

#homepage.stack>*+* {
  margin-top: $cdr-space-four-x;

  @include cdr-xs-mq-only {
    margin-top: $cdr-space-three-x;
  }
}

#homepage.stack .stack-exception {
  margin-top: 0;
}

.homepage {
  overflow: hidden;

  &__shipping-banner {
    padding-top: $cdr-space-one-and-a-half-x;
    padding-bottom: $cdr-space-one-and-a-half-x;
    margin: $cdr-space-three-x auto $cdr-space-two-x;

    @include cdr-sm-mq-up {
      padding-top: $cdr-space-three-x;
      padding-bottom: $cdr-space-three-x;
    }
  }

  &__container {
    @include cdr-container;
  }

  &__recommendations {
    overflow: hidden;
  }

  &__clearance {
    margin-bottom: $cdr-space-four-x;
  }

  .button-inline {
    @include cdr-text-utility-sans-200;
    color: $cdr-color-text-inverse;
    background-color: transparent;
    border: none;
    padding: 0 0 .2rem 0;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;

    @include cdr-sm-mq-down {
      padding: 0;
    }

    &:hover,
    &:focus,
    &:active,
    &:visited {
      color: $cdr-color-text-inverse;
      text-decoration: none;
    }
  }
}

.top-categories {
  &__heading {
    margin: 0 0 $cdr-space-one-and-a-half-x;

    @include cdr-sm-mq-up {
      margin: 0 0 2.4rem
    }
  }
}

.membership {
  &__zone {
    // Sorry about the importants. Its needed to override the super specific stack CSS.
    margin-top: $cdr-space-one-and-a-half-x !important;
    @include cdr-sm-mq-up {
      margin-top: $cdr-space-two-x !important;
    }
  }

  &__placement {
    position: relative;

    :deep(.story__container--media) {
      @include cdr-sm-mq-up {
        order: 1;
      }
    }
    :deep(.landing-stories__background) {
      position: relative;
    }
    :deep(.story--background-fill) {
      place-self: center;
      border-radius: 0;
      padding-top: 0;

      @include cdr-sm-mq-up {
        padding-left: 3.2rem;
        padding-right: 0;
        padding-bottom: 0;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $cdr-color-background-secondary;
        z-index: -1;
      }
    }
    :deep(.story__image) {
      border-radius: 0;
    }
  }

  &__placement-handwriting {
    position: absolute;
    top: 1%;
    left: -2%;
    @include cdr-sm-mq-up {
      left: 48%;
    }
    @include cdr-md-mq-up {
      left: 48%;
    }
  }

  &__why-join {
    margin-top: $cdr-space-one-and-a-half-x;
    @include cdr-sm-mq-up {
      margin-top: $cdr-space-two-x;
    }
  }
}

.homepage__outlet {
  :deep(.ls-content-block__lockup img) {
    width: 218px;
  }
}
</style>
